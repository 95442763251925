/*global google*/
import { FC, useState, useEffect } from "react";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useJsApiLoader,
  OverlayView
} from "@react-google-maps/api";
import MarkerWithLabel from "react-google-maps/lib/components/addons/MarkerWithLabel";

import useWindowSize from "../../../hooks/UseWindowSize";
import stylesArray from "./GoogleMapStyles";
import Box from "@mui/material/Typography";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import ListingCard from "../../ListingCard";
import MapListingCard from "../../ListingCard/MapListingCard";

interface Props {
  center: { lat: number; lng: number };
  height?: string | number | undefined;
  width?: string | number | undefined;
  markers?: {
    lat: number;
    lng: number;
    type: string;
    label?: string;
  }[];
  selectedMarker?: number;
  zoom?: number;
  clickable?: boolean;
}

interface Size {
  width: string | number | undefined;
  height: string | number | undefined;
}

type Libraries = (
  | "drawing"
  | "geometry"
  | "localContext"
  | "places"
  | "visualization"
)[];

const libraries: Libraries = ['places'];

const Map: FC<Props> = ({
  center,
  height,
  width,
  markers,
  selectedMarker,
  zoom = 10,
  clickable = true,
  isFullScreen = false
}) => {
  const [containerStyle, setContainerStyle] = useState<Size>({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [mapOptions, setMapOptions] = useState({
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    zoomControl: false,
    zoomControlOptions: { position: 7 },
    keyboardShortcuts: false,
    styles: stylesArray,
  });


  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyAnlMeQQ072sRw22U6aG0zLTHbyh0g8TB0",
    libraries
  });

  const size = useWindowSize();

  useEffect(() => {
    if (!height && !width) {
      setContainerStyle(size);
    } else if (width) {
      setContainerStyle({
        width,
        height: size.height,
      });
    } else if (height) {
      setContainerStyle({
        width: size.width,
        height,
      });
    } else {
      setContainerStyle({ width, height });
    }
    if (size.width > 720) {
      setMapOptions({ ...mapOptions, zoomControl: true });
    } else {
      setMapOptions({ ...mapOptions, zoomControl: false });
    }
  }, [size]);

  const [showInfo, setShowInfo] = useState(false);
  const [showInfoPosition, setShowInfoPostion] = useState({
    lat: 0,
    lng: 0,
  });

  const [showInfoContents, setShowInfoContents] = useState(null);

  const getPixelPositionOffset = (offsetWidth, offsetHeight, labelAnchor) => {
      return {
          x: offsetWidth + labelAnchor.x,
          y: offsetHeight + labelAnchor.y,
      };
  };

  const renderMap = () => (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      options={mapOptions}
      zoom={size.width > 720 ? zoom : zoom - 1}
    >
      {markers !== undefined &&
        markers.map((marker, key) => {
          if (marker.type === "hotel") {
         
            // return (<>
            //   <Marker
            //     key={key+'1'}
            //     position={marker.position}
            //     {...marker}
            //   />
            //   <OverlayView
            //       key={key}
            //       position={marker.position}
            //       mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            //       getPixelPositionOffset={(x, y) => getPixelPositionOffset(x, y, { x: -30, y: -15 })}>
            //       <div
            //           style={{
            //               background: `#203254`,
            //               padding: `7px 12px`,
            //               fontSize: '11px',
            //               color: `white`,
            //               borderRadius: '4px',
            //           }}
            //       >
            //           ${marker.lowestAveragePrice}
            //       </div>
            //   </OverlayView>

            // </>)
            return (
              <Marker
                position={marker}
                animation={2}
                key={key}
                label={isFullScreen ? `$${Math.abs(marker.lowestAveragePrice).toFixed(0)}` : ''}   
                labelStyle={{ color: 'red'}}     
                icon={{
                  url: isFullScreen
                    ? "https://www.actuall.eu/wp-content/uploads/2016/10/cropped-White-box.jpg"
                    : "https://storage.googleapis.com/romingo-development-public/images/front-end/icons/hotel_marker.svg",
                  scaledSize: new google.maps.Size(45, 35),
                }}
                onClick={(e: google.maps.MapMouseEvent) => {
                  console.log(marker)
                  if (!clickable) {
                    return
                  }
                  if (marker.label) {
                    setShowInfoPostion({
                      lat: marker.lat,
                      lng: marker.lng,
                    });
                    setShowInfoContents(marker.hotel);
                    setShowInfo(true);
                  }
                }}
              />
            );
          } else {
            return (
              <Marker
                position={marker}
                animation={2}
                key={key}
                icon={{
                  url: "https://storage.googleapis.com/romingo-development-public/images/front-end/icons/google-map-potty-park.svg",
                  scaledSize: new google.maps.Size(30, 30),
                }}
                onClick={(e: google.maps.MapMouseEvent) => {
                  if (marker.label) {
                    setShowInfoPostion({
                      lat: marker.lat,
                      lng: marker.lng,
                    });
                    console.log(marker.hotel)
                    setShowInfoContents(marker.hotel);
                    setShowInfo(true);
                  }
                }}
              />
            );
          }
        })}
      {showInfo && (
        <InfoWindow
          position={showInfoPosition}
          onCloseClick={() => {
            setShowInfo(false);
          }}
        >
          <ListingCard {...showInfoContents} hotel={showInfoContents} />
        </InfoWindow>
      )}
    </GoogleMap>
  );

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? (
    renderMap()
  ) : (
    <Skeleton
      variant="rectangular"
      animation="wave"
      height="100%"
      width="100%"
    />
  );
};

export default Map;
