export const theme = {
  palette: {
    primary: {
      main: "#03989E",
      black: '#000000',
    },
    secondary: {
      lighter: "#d9f7fc",
      main: "#f3f5f9",
    },
    success: {
      main: "#ACC966",
    },
    warning: {
      main: "#F9C171",
    },
    info: { main: "#F4DAC9" },
    error: {
      main: "#BC4749",
    },
    lightBackground: {
      main: "#fcf5f0",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.78)",
    },
  },
  typography: {
    fontFamily: `"sansita-light", "sansita-light", sans-serif`,
    h1: {
      fontFamily: "sansita-light",
      fontWeight: 900,
    },
    h2: {
      fontFamily: "sansita-light",
      fontWeight: 900,
      fontSize: '2rem',
      '@media (min-width: 600px)': {
        fontSize: '2rem'
      }
    },
    h3: {
      fontFamily: "sansita-light",
      fontWeight: 900,
    },
    h4: {
      fontFamily: "sansita-light",
      fontWeight: 900,
      fontSize: '1.75rem',
      '@media (min-width: 600px)': {
        fontSize: '1.75rem'
      }
    },
    h5: {
      fontFamily: "sansita-light",
      fontWeight: 900,
      fontSize: '1.5rem',
      '@media (min-width: 600px)': {
        fontSize: '1.5rem'
      }
    },
    h6: {
      fontFamily: "sansita-light",
      fontWeight: 700,
      letterSpacing: 0.5,
    },
    body1: {
      fontFamily: "overpass-light",
      fontWeight: 500,
      color: "#000000c7",
    },
    body2: {
      fontFamily: "overpass-light",
      fontWeight: 500,
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
    p: {
      fontFamily: 'sansita-light',
      fontWeight: 500,
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
    p2: {
      fontFamily: "overpass-light",
      fontWeight: 500,
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
    base: {
      fontFamily: 'sansita-light',
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
    base2: {
      fontFamily: "overpass-light",
      fontWeight: 500,
      fontSize: '1rem'
    },
    subtitle1: {
      fontFamily: "overpass-light",
      fontWeight: 300,
    },
    subtitle2: {
      fontFamily: "overpass-light",
      fontWeight: 300,
    },
    button: {
      fontFamily: "sansita-light",
      fontWeight: 300,
    },
    caption: {
      fontFamily: "overpass-light",
      fontWeight: 300,
    },
    overline: {
      fontFamily: "overpass-light",
      fontWeight: 300,
    },
  },
};
