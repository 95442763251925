import { FC } from "react";
import { Switch, Route, Redirect } from "react-router";
import loadable from '@loadable/component'

import HomePage from './pages/HomePage'
// const HomePage = loadable(() => import('./pages/HomePage'))
const ListingPage = loadable(() => import('./pages/ListingPage'))
import DetailsPage from './pages/DetailsPage'
// const DetailsPage = loadable(() => import('./pages/DetailsPage'))
const ProfilePage = loadable(() => import('./pages/ProfilePage'))
const CheckoutPage = loadable(() => import('./pages/CheckoutPage'))

const ManageReservationPage = loadable(() => import('./pages/ManageReservationPage'))
const YourReservationPage = loadable(() => import('./pages/YourReservationPage'))
const ErrorPage = loadable(() => import('./pages/ErrorPage'))
const About = loadable(() => import('./pages/StaticPages/About'))
const FAQ = loadable(() => import('./pages/StaticPages/FAQ'))
const ContactPage = loadable(() => import('./pages/StaticPages/Contact'))
const Privacy = loadable(() => import('./pages/StaticPages/Privacy'))
const Terms = loadable(() => import('./pages/StaticPages/Terms'))
const ListYourProperty = loadable(() => import('./pages/StaticPages/ListYourProperty'))
const RedRoverPartner = loadable(() => import('./pages/StaticPages/RedRoverPartner'))
const Application = loadable(() => import('./pages/StaticPages/Application'))
const CreateAccount = loadable(() => import('./pages/CreateAccount'))

const Austin = loadable(() => import('./pages/Cities/Austin'))
const Dallas = loadable(() => import('./pages/Cities/Dallas'))
const Denver = loadable(() => import('./pages/Cities/Denver'))
const Houston = loadable(() => import('./pages/Cities/Houston'))
const LosAngeles = loadable(() => import('./pages/Cities/LosAngeles'))
const PalmSprings = loadable(() => import('./pages/Cities/PalmSprings'))
const Phoenix = loadable(() => import('./pages/Cities/Phoenix'))
const Portland = loadable(() => import('./pages/Cities/Portland'))
const SaltLakeCity = loadable(() => import('./pages/Cities/SaltLakeCity'))
const SanAntonio = loadable(() => import('./pages/Cities/SanAntonio'))
const SanDiego = loadable(() => import('./pages/Cities/SanDiego'))
const SanFrancisco = loadable(() => import('./pages/Cities/SanFrancisco'))
const SantaFe = loadable(() => import('./pages/Cities/SantaFe'))
const Scottsdale = loadable(() => import('./pages/Cities/Scottsdale'))
const Seattle = loadable(() => import('./pages/Cities/Seattle'))
const Tucson = loadable(() => import('./pages/Cities/Tucson'))

//NEW
const Atlanta = loadable(() => import('./pages/Cities/Atlanta'))
const Baltimore = loadable(() => import('./pages/Cities/Baltimore'))
const Boston = loadable(() => import('./pages/Cities/Boston'))
const Charlotte = loadable(() => import('./pages/Cities/Charlotte'))
const Chicago = loadable(() => import('./pages/Cities/Chicago'))
const Cleveland = loadable(() => import('./pages/Cities/Cleveland'))
const Detroit = loadable(() => import('./pages/Cities/Detroit'))
const Indianapolis = loadable(() => import('./pages/Cities/Indianapolis'))
const Miami = loadable(() => import('./pages/Cities/Miami'))
const Milwaukee = loadable(() => import('./pages/Cities/Milwaukee'))
const Minneapolis = loadable(() => import('./pages/Cities/Minneapolis'))
//nashville
const NewOrleans = loadable(() => import('./pages/Cities/NewOrleans'))
const NewYork = loadable(() => import('./pages/Cities/NewYork'))
const Washington = loadable(() => import('./pages/Cities/Washington'))



const Blog = loadable(() => import('./pages/Blog'))
const BlogPost = loadable(() => import('./pages/BlogPost'))

const HiltonPolicy = loadable(() => import('./pages/PolicyPages/Hilton'))
const HyattPolicy = loadable(() => import('./pages/PolicyPages/Hyatt'))
const IHGPolicy = loadable(() => import('./pages/PolicyPages/IHG'))
const MarriottPolicy = loadable(() => import('./pages/PolicyPages/Marriott'))
const Motel6Policy = loadable(() => import('./pages/PolicyPages/Motel6'))
const BoutiquePolicy = loadable(() => import('./pages/PolicyPages/Boutique'))


import { authService } from "./services/authService.js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { IntercomProvider } from 'react-use-intercom';
import ListingPageNew from "./pages/ListingPage/ListingPageNew";
import DetailsPage1 from "./pages/DetailsPage/DetailsPage1";

interface RouteInterface {
  path: string;
  component: FC<any>;
  requireAuth: boolean;
}

const routes: RouteInterface[] = [
  {
    path: "/",
    component: HomePage,
    requireAuth: false,
  },
  {
    path: "/listings",
    component: ListingPageNew,
    requireAuth: false,
  },
  {
    path: '/profile',
    component: ProfilePage,
    requireAuth: false
  },
  {
    path: "/pet-friendly-hotels/:state/:city/:name",
    component: DetailsPage1,
    requireAuth: false,
  },
  {
    path: "/reservation/manage",
    component: ManageReservationPage,
    requireAuth: false,
  },
  {
    path: '/reservation/details',
    component: YourReservationPage,
    requireAuth: false,
  },
  {
    path: "/about",
    component: About,
    requireAuth: false,
  },
  {
    path: "/faq",
    component: FAQ,
    requireAuth: false,
  },
  {
    path: "/privacy",
    component: Privacy,
    requireAuth: false,
  },
  {
    path: "/terms-of-use",
    component: Terms,
    requireAuth: false,
  },
  {
    path: "/romingo-partners",
    component: RedRoverPartner,
    requireAuth: false,
  },
  {
    path: "/list-your-property",
    component: ListYourProperty,
    requireAuth: false,
  },
  {
    path: "/application",
    component: Application,
    requireAuth: false,
  },
  {
    path: "/blog/",
    component: Blog,
    requireAuth: false,
  },
  {
    path: "/blog/post/:id",
    component: BlogPost,
    requireAuth: false,
  },
  {
    path: '/create-account',
    component: CreateAccount,
    requireAuth: false,
  }
];

const AuthGuards = (props: any) => {
  const token = authService.getToken();
  if (token && props.children) {
    return props.children;
  }

  return <Redirect to={"/login"} />;
};

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_CLIENT_KEY as string
);

const Routes = () => {
  return (
    <Switch>
      {routes.map((route, key) => {
        if (!route.requireAuth)
          return (
            <Route
              exact
              path={route.path}
              component={route.component}
              key={key}
            />
          );
        else
          return (
            <AuthGuards key={key}>
              <Route exact path={route.path} component={route.component} />
            </AuthGuards>
          );
      })}

      <Route exact path="/contact">
        <IntercomProvider appId="qa6datd3">
          <ContactPage />
        </IntercomProvider>
      </Route>

      <Route exact path="/checkout*">
        <Elements stripe={stripePromise}>
          <CheckoutPage />
        </Elements>
      </Route>

      <Route exact path="/pet-friendly-hotels/austin-texas"><Austin /></Route>
      <Route exact path="/pet-friendly-hotels/dallas-texas"><Dallas /></Route>
      <Route exact path="/pet-friendly-hotels/houston-texas"><Houston /></Route>
      <Route exact path="/pet-friendly-hotels/phoenix-arizona"><Phoenix /></Route>
      <Route exact path="/pet-friendly-hotels/scottsdale-arizona"><Scottsdale /></Route>
      <Route exact path="/pet-friendly-hotels/tucson-arizona"><Tucson /></Route>
      <Route exact path="/pet-friendly-hotels/santa-fe-new-mexico"><SantaFe /></Route>
      <Route exact path="/pet-friendly-hotels/san-antonio-texas"><SanAntonio /></Route>
      <Route exact path="/pet-friendly-hotels/denver-colorado"><Denver /></Route>
      <Route exact path="/pet-friendly-hotels/seattle-washington"><Seattle /></Route>
      <Route exact path="/pet-friendly-hotels/portland-oregon"><Portland /></Route>
      <Route exact path="/pet-friendly-hotels/salt-lake-city-utah"><SaltLakeCity /></Route>
      <Route exact path="/pet-friendly-hotels/palm-springs-california"><PalmSprings /></Route>
      <Route exact path="/pet-friendly-hotels/los-angeles-california"><LosAngeles /></Route>
      <Route exact path="/pet-friendly-hotels/san-diego-california"><SanDiego /></Route>
      <Route exact path="/pet-friendly-hotels/san-francisco-california"><SanFrancisco /></Route>

      <Route exact path="/pet-friendly-hotels/atlanta-georgia"><Atlanta /></Route>
      <Route exact path="/pet-friendly-hotels/baltimore-maryland"><Baltimore /></Route>
      <Route exact path="/pet-friendly-hotels/boston-massachusetts"><Boston /></Route>
      <Route exact path="/pet-friendly-hotels/charlotte-north-carolina"><Charlotte /></Route>
      <Route exact path="/pet-friendly-hotels/chicago-illinois"><Chicago /></Route>
      <Route exact path="/pet-friendly-hotels/cleveland-ohio"><Cleveland /></Route>
      <Route exact path="/pet-friendly-hotels/detroit-michigan"><Detroit /></Route>
      <Route exact path="/pet-friendly-hotels/indianapolis-indiana"><Indianapolis /></Route>
      <Route exact path="/pet-friendly-hotels/miami-florida"><Miami /></Route>
      <Route exact path="/pet-friendly-hotels/milwaukee-wisconsin"><Milwaukee /></Route>
      <Route exact path="/pet-friendly-hotels/minneapolis-minnesota"><Minneapolis /></Route>
      <Route exact path="/pet-friendly-hotels/new-orleans-louisiana"><NewOrleans /></Route>
      <Route exact path="/pet-friendly-hotels/new-york-new-york"><NewYork /></Route>
      <Route exact path="/pet-friendly-hotels/washington-dc"><Washington /></Route>

     
      <Route exact path="/hilton-pet-policy"><HiltonPolicy /></Route>
      <Route exact path="/hyatt-pet-policy"><HyattPolicy /></Route>
      <Route exact path="/ihg-pet-policy"><IHGPolicy /></Route>
      <Route exact path="/marriott-pet-policy"><MarriottPolicy /></Route>
      <Route exact path="/motel-6-pet-policy"><Motel6Policy /></Route>
      <Route exact path="/boutique-pet-policy"><BoutiquePolicy /></Route>

      <Route component={ErrorPage} />
    </Switch>
  );
};

export default Routes;
